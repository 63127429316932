import React from "react"
import { graphql, Link } from "gatsby"
import { FaBook } from "react-icons/fa"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const courseTitles = data.allMarkdownRemark.distinct
  const images = data.allImageSharp.nodes
  const courseLinks = courseTitles.map(c => ({
    name: c,
    link: `/courses/${c
      .toLowerCase()
      .split(" ")
      .join("-")}`,
    coverImage: images.find(i =>
      i.resize.originalName.toLowerCase().includes(c.toLowerCase())
    ),
  }))
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All Courses" />
      <h4>All Courses</h4>
      <div className="flex-row-start">
        {courseLinks.map((c, i) => (
          <div className="flex-column-center course-container" key={i}>
            <img src={c.coverImage.resize.src} />
            <Link
              to={`${c.link}/`}
              state={{
                prevLocation: { url: location.pathname, label: "courses" },
              }}
            >
              <h4> {c.name}</h4>
            </Link>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        popularTopics
      }
    }
    allMarkdownRemark(filter: { frontmatter: { course: { ne: null } } }) {
      distinct(field: frontmatter___course)
      nodes {
        frontmatter {
          course
        }
      }
    }
    allImageSharp {
      nodes {
        fluid {
          originalName
          src
        }
        resize(height: 75, width: 75) {
          src
          originalName
        }
      }
    }
  }
`
